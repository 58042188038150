import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import { fetchProductCatalogProjectsLimited } from "api";
import SingletonApi from "api/SingletonApi";

import { fetchProjectLimitedSuccess } from "store/project/actions";
import { selectIsCurrentTenantFinexity } from "store/app/selectors";
import { getTenantFromHost, getBaseApiURL } from "common/helper";
import PropertyPage from "./marketplace";

const FINX_COOKIE = require("common/constants");

const Index = () => {
    return <PropertyPage />;
};

Index.getInitialProps = async ({ store: { dispatch }, isServer, req }) => {
    if (isServer) {
        const customWindow = { location: { host: req?.hostname } };
        const baseApiUrl = getBaseApiURL(customWindow);
        const currentTenant = getTenantFromHost(req?.hostname);
        const userEmail = req.cookies[FINX_COOKIE.USER_EMAIL];

        SingletonApi.setBaseURL(baseApiUrl);
        SingletonApi.setHeader("finexity-tenant", currentTenant);

        const response = await fetchProductCatalogProjectsLimited(10, "", userEmail);
        const projects = response?.data?.data?.productCatalogProjects?.projects;
        projects && dispatch(fetchProjectLimitedSuccess(projects));
    }

    return { isServer };
};

const mapStateToProps = createStructuredSelector({
    isCurrentTenantFinexity: selectIsCurrentTenantFinexity,
});

export default connect(mapStateToProps)(Index);
